<div (keyup.enter)="submit()">
    <h2>{{ '::PSTDiscoverySettings' | abpLocalization }}</h2>
    <hr class="my-4" />
    <form *ngIf="form" [formGroup]="form">

        <div class="mb-3 form-check">
          <input
            class="form-check-input"
            formControlName="autoEnableDiscoveryOnNewAgents"
            id="auto-enable-discovery-on-new-agents"
            name="autoEnableDiscoveryOnNewAgents"
            type="checkbox"
          /><label class="form-check-label" for="autoEnableDiscoveryOnNewAgents">{{
          '::AutoEnableDiscoveryOnNewAgents' | abpLocalization
          }}</label>
        </div>

        <div class="mb-3 form-check">
              <input
                class="form-check-input"
                formControlName="autoEnableDiscoveryOnNewUsers"
                id="autoEnableDiscoveryOnNewUsers"
                name="auto-enable-discovery-on-new-users"
                type="checkbox"
              /><label class="form-check-label" for="autoEnableDiscoveryOnNewUsers">{{
              '::AutoEnableDiscoveryOnNewUsers' | abpLocalization
              }}</label>
        </div>

        <div class="mb-3 form-check">
            <input
              class="form-check-input"
              formControlName="enablePstScan"
              id="enable-pst-scan"
              name="enablePstScan"
              type="checkbox"
            /><label class="form-check-label" for="use-ssl">{{
            '::EnablePstDiscovery' | abpLocalization
            }}</label>
          </div>

          <div class="mb-3 form-group">
            <label class="form-check-label">{{ '::PSTDiscoveryInterval' | abpLocalization }}</label>
            <input class="form-control" formControlName="interval" type="number"/>
          </div>

          <div class="mb-3 form-group">
            <label class="form-check-label">{{ '::DiscoverPSTTargets' | abpLocalization }}</label>
            <ngx-bootstrap-multiselect
            class="form-control"
            [options]="pstScanTarget"
            (ngModelChange)="onScanChange($event)"
            formControlName = "pstTarget"
            [settings]="settings">
            </ngx-bootstrap-multiselect>
          </div>

          <div class="mb-3 form-group">
            <label class="form-check-label">{{ '::DiscoverPSTTargetExclusions' | abpLocalization }}</label>
            <ngx-bootstrap-multiselect
            class="form-control"
            [options]="pstScanTargetExclusions"
            (ngModelChange)="onExclusionsScanChange($event)"
            formControlName = "pstTargetExclusions"
            [settings]="settings">
            </ngx-bootstrap-multiselect>
          </div>


    </form>
    <hr class="my-4"/>
    <div>
        <abp-button (click)="submit()" [loading]="loading" [disabled]="form?.invalid" iconClass="fa fa-save">{{
          'AbpAccount::Save' | abpLocalization
          }}</abp-button>
      </div>
</div>
