import * as i0 from '@angular/core';
import { Injectable, Component, Inject, Input, Directive, inject, APP_INITIALIZER, makeEnvironmentProviders, NgModule } from '@angular/core';
import * as i3 from '@angular/common';
import { DOCUMENT } from '@angular/common';
import * as i1 from '@abp/ng.core';
import { InternalStore, CoreModule } from '@abp/ng.core';
import * as i2 from '@angular/router';
import { Router, RouterModule } from '@angular/router';
import { UserMenuService } from '@abp/ng.theme.shared';
const _c0 = a0 => [a0];
function LinkComponent_Conditional_0_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 0);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(2, _c0, ctx_r0.cookiePolicyUrl));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.cookiePolicy);
  }
}
function LinkComponent_Conditional_0_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 1);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("href", i0.ɵɵpureFunction1(2, _c0, ctx_r0.cookiePolicyUrl), i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.cookiePolicy);
  }
}
function LinkComponent_Conditional_0_Conditional_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 0);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(2, _c0, ctx_r0.privacyPolicyUrl));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.privacyPolicy);
  }
}
function LinkComponent_Conditional_0_Conditional_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 1);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("href", i0.ɵɵpureFunction1(2, _c0, ctx_r0.privacyPolicyUrl), i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.privacyPolicy);
  }
}
function LinkComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(2, LinkComponent_Conditional_0_Conditional_2_Template, 2, 4, "a", 0)(3, LinkComponent_Conditional_0_Conditional_3_Template, 2, 4, "a", 1);
    i0.ɵɵelementStart(4, "span");
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(6, LinkComponent_Conditional_0_Conditional_6_Template, 2, 4, "a", 0)(7, LinkComponent_Conditional_0_Conditional_7_Template, 2, 4, "a", 1);
    i0.ɵɵelementStart(8, "span");
    i0.ɵɵtext(9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.splittedArr[0], " ");
    i0.ɵɵadvance();
    i0.ɵɵconditional(!ctx_r0.isHrefCookie ? 2 : -1);
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r0.isHrefCookie ? 3 : -1);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r0.splittedArr[1]);
    i0.ɵɵadvance();
    i0.ɵɵconditional(!ctx_r0.isHrefPrivacy ? 6 : -1);
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r0.isHrefPrivacy ? 7 : -1);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r0.splittedArr[2]);
  }
}
function LinkComponent_Conditional_1_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 0);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(2, _c0, ctx_r0.cookiePolicyUrl));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.cookiePolicy);
  }
}
function LinkComponent_Conditional_1_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 1);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("href", i0.ɵɵpureFunction1(2, _c0, ctx_r0.cookiePolicyUrl), i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.cookiePolicy);
  }
}
function LinkComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(2, LinkComponent_Conditional_1_Conditional_2_Template, 2, 4, "a", 0)(3, LinkComponent_Conditional_1_Conditional_3_Template, 2, 4, "a", 1);
    i0.ɵɵelementStart(4, "span");
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.policyTranslatedText[0], " ");
    i0.ɵɵadvance();
    i0.ɵɵconditional(!ctx_r0.isHrefCookie ? 2 : -1);
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r0.isHrefCookie ? 3 : -1);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.policyTranslatedText[1], " ");
  }
}
function LinkComponent_Conditional_2_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 0);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(2, _c0, ctx_r0.privacyPolicyUrl));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.privacyPolicy);
  }
}
function LinkComponent_Conditional_2_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 1);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("href", i0.ɵɵpureFunction1(2, _c0, ctx_r0.privacyPolicyUrl), i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.privacyPolicy);
  }
}
function LinkComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(2, LinkComponent_Conditional_2_Conditional_2_Template, 2, 4, "a", 0)(3, LinkComponent_Conditional_2_Conditional_3_Template, 2, 4, "a", 1);
    i0.ɵɵelementStart(4, "span");
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.policyTranslatedText[0], " ");
    i0.ɵɵadvance();
    i0.ɵɵconditional(!ctx_r0.isHrefPrivacy ? 2 : -1);
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r0.isHrefPrivacy ? 3 : -1);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.policyTranslatedText[1], " ");
  }
}
const _c1 = a0 => ({
  componentKey: a0
});
function GdprCookieConsentComponent_ng_container_0_Conditional_1_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1)(1, "div", 2)(2, "div", 3)(3, "div", 4)(4, "div", 5)(5, "p", 6);
    i0.ɵɵtext(6);
    i0.ɵɵpipe(7, "abpLocalization");
    i0.ɵɵelementContainer(8, 7);
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(9, "button", 8);
    i0.ɵɵlistener("click", function GdprCookieConsentComponent_ng_container_0_Conditional_1_Conditional_0_Template_button_click_9_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.acceptCookie());
    });
    i0.ɵɵtext(10);
    i0.ɵɵpipe(11, "abpLocalization");
    i0.ɵɵelementEnd()()()()();
  }
  if (rf & 2) {
    const options_r3 = i0.ɵɵnextContext();
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(7, 3, "AbpGdpr::ThisWebsiteUsesCookie"), " ");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("link", options_r3);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(11, 5, "AbpGdpr::Accept"), " ");
  }
}
function GdprCookieConsentComponent_ng_container_0_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, GdprCookieConsentComponent_ng_container_0_Conditional_1_Conditional_0_Template, 12, 7, "div", 1);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵconditional(ctx.isEnabled && ctx_r1.showCookieConsent ? 0 : -1);
  }
}
function GdprCookieConsentComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, GdprCookieConsentComponent_ng_container_0_Conditional_1_Template, 1, 1);
    i0.ɵɵpipe(2, "async");
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    let tmp_1_0;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵconditional((tmp_1_0 = i0.ɵɵpipeBind1(2, 1, ctx_r1.consentOptions$)) ? 1 : -1, tmp_1_0);
  }
}
class CookieConsentService {
  constructor() {
    this.#store = new InternalStore({});
    this.cookieConsentOptions$ = this.#store.sliceState(state => state);
  }
  #store;
  get cookieConsentOptions() {
    return this.#store.state;
  }
  setOptions(options) {
    this.#store.patch({
      isEnabled: options.isEnabled,
      cookiePolicyUrl: options.cookiePolicyUrl,
      privacyPolicyUrl: options.privacyPolicyUrl,
      expireDate: options.expireDate
    });
  }
  static {
    this.ɵfac = function CookieConsentService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CookieConsentService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CookieConsentService,
      factory: CookieConsentService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CookieConsentService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
var eGdprConfigComponents;
(function (eGdprConfigComponents) {
  eGdprConfigComponents["CookieConsent"] = "Gdpr.CookieConsentComponent";
})(eGdprConfigComponents || (eGdprConfigComponents = {}));
class LinkComponent {
  constructor(localizationService, document) {
    this.localizationService = localizationService;
    this.document = document;
    this.isHrefPrivacy = false;
    this.isHrefCookie = false;
    this.window = this.document.defaultView;
  }
  ngOnInit() {
    this.initLanguage();
    this.changeLanguage();
    this.sameOrigin();
  }
  initLanguage() {
    if (this.link.cookiePolicyUrl && this.link.privacyPolicyUrl) {
      this.cookiePolicyUrl = this.link.cookiePolicyUrl;
      this.privacyPolicyUrl = this.link.privacyPolicyUrl;
      this.cookiePolicy = this.localizationService.instant('AbpGdpr::CookiePolicy');
      this.privacyPolicy = this.localizationService.instant('AbpGdpr::PrivacyPolicy');
      const cookieConsentAgreePolicies = this.localizationService.instant('AbpGdpr::CookieConsentAgreePolicies');
      this.splittedArr = cookieConsentAgreePolicies.split(/["{}01"]+/);
    } else if (this.link.cookiePolicyUrl) {
      this.cookiePolicy = this.localizationService.instant('AbpGdpr::CookiePolicy');
      const cookieConsentAgreePolicy = this.localizationService.instant('AbpGdpr::CookieConsentAgreePolicy');
      this.policyTranslatedText = cookieConsentAgreePolicy.split(/["{}01"]+/);
      this.cookiePolicyUrl = this.link.cookiePolicyUrl;
    } else if (this.link.privacyPolicyUrl) {
      this.privacyPolicy = this.localizationService.instant('AbpGdpr::PrivacyPolicy');
      const cookieConsentAgreePolicy = this.localizationService.instant('AbpGdpr::CookieConsentAgreePolicy');
      this.policyTranslatedText = cookieConsentAgreePolicy.split(/["{}01"]+/);
      this.privacyPolicyUrl = this.link.privacyPolicyUrl;
    }
  }
  changeLanguage() {
    this.localizationService.languageChange$.subscribe(value => {
      if (this.link.cookiePolicyUrl && this.link.privacyPolicyUrl) {
        this.cookiePolicyUrl = this.link.cookiePolicyUrl;
        this.privacyPolicyUrl = this.link.privacyPolicyUrl;
        this.cookiePolicy = this.localizationService.instant('AbpGdpr::CookiePolicy');
        this.privacyPolicy = this.localizationService.instant('AbpGdpr::PrivacyPolicy');
        const cookieConsentAgreePolicies = this.localizationService.instant('AbpGdpr::CookieConsentAgreePolicies');
        this.splittedArr = cookieConsentAgreePolicies.split(/["{}01"]+/);
      } else if (this.link.cookiePolicyUrl) {
        this.cookiePolicy = this.localizationService.instant('AbpGdpr::CookiePolicy');
        const cookieConsentAgreePolicy = this.localizationService.instant('AbpGdpr::CookieConsentAgreePolicy');
        this.policyTranslatedText = cookieConsentAgreePolicy.split(/["{}01"]+/);
        this.cookiePolicyUrl = this.link.cookiePolicyUrl;
      } else if (this.link.privacyPolicyUrl) {
        this.privacyPolicy = this.localizationService.instant('AbpGdpr::PrivacyPolicy');
        const cookieConsentAgreePolicy = this.localizationService.instant('AbpGdpr::CookieConsentAgreePolicy');
        this.policyTranslatedText = cookieConsentAgreePolicy.split(/["{}01"]+/);
        this.privacyPolicyUrl = this.link.privacyPolicyUrl;
      }
    });
  }
  sameOrigin() {
    const siteUrl = this.window.location.host;
    if (this.cookiePolicyUrl) {
      const cookiePolicyUrl = /:\/\/([^\/]+)/.exec(this.cookiePolicyUrl);
      if (cookiePolicyUrl) {
        if (siteUrl !== cookiePolicyUrl[1]) {
          this.isHrefCookie = true;
        }
      }
    }
    if (this.privacyPolicyUrl) {
      const privacyPolicyUrl = /:\/\/([^\/]+)/.exec(this.privacyPolicyUrl);
      if (privacyPolicyUrl) {
        if (siteUrl !== privacyPolicyUrl[1]) {
          this.isHrefPrivacy = true;
        }
      }
    }
  }
  static {
    this.ɵfac = function LinkComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LinkComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(DOCUMENT));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LinkComponent,
      selectors: [["abp-link"]],
      inputs: {
        link: "link"
      },
      decls: 3,
      vars: 3,
      consts: [[1, "text-white", 3, "routerLink"], ["target", "_blank", 1, "text-white", 3, "href"]],
      template: function LinkComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, LinkComponent_Conditional_0_Template, 10, 7)(1, LinkComponent_Conditional_1_Template, 6, 4)(2, LinkComponent_Conditional_2_Template, 6, 4);
        }
        if (rf & 2) {
          i0.ɵɵconditional(ctx.cookiePolicyUrl && ctx.privacyPolicyUrl ? 0 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.cookiePolicyUrl && !ctx.privacyPolicyUrl ? 1 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(!ctx.cookiePolicyUrl && ctx.privacyPolicyUrl ? 2 : -1);
        }
      },
      dependencies: [i2.RouterLink],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinkComponent, [{
    type: Component,
    args: [{
      selector: 'abp-link',
      template: "@if (cookiePolicyUrl && privacyPolicyUrl) {\r\n  <span>\r\n    {{ splittedArr[0] }}\r\n  </span>\r\n  @if (!isHrefCookie) {\r\n    <a class=\"text-white\" [routerLink]=\"[cookiePolicyUrl]\">{{ cookiePolicy }}</a>\r\n  }\r\n\r\n  @if (isHrefCookie) {\r\n    <a class=\"text-white\" target=\"_blank\" [href]=\"[cookiePolicyUrl]\">{{ cookiePolicy }}</a>\r\n  }\r\n  <span>{{ splittedArr[1] }}</span>\r\n\r\n  @if (!isHrefPrivacy) {\r\n    <a class=\"text-white\" [routerLink]=\"[privacyPolicyUrl]\">{{ privacyPolicy }}</a>\r\n  }\r\n\r\n  @if (isHrefPrivacy) {\r\n    <a class=\"text-white\" target=\"_blank\" [href]=\"[privacyPolicyUrl]\">{{ privacyPolicy }}</a>\r\n  }\r\n  <span>{{ splittedArr[2] }}</span>\r\n}\r\n\r\n@if (cookiePolicyUrl && !privacyPolicyUrl) {\r\n  <span>\r\n    {{ policyTranslatedText[0] }}\r\n  </span>\r\n  @if (!isHrefCookie) {\r\n    <a class=\"text-white\" [routerLink]=\"[cookiePolicyUrl]\">{{ cookiePolicy }}</a>\r\n  }\r\n  @if (isHrefCookie) {\r\n    <a class=\"text-white\" target=\"_blank\" [href]=\"[cookiePolicyUrl]\">{{ cookiePolicy }}</a>\r\n  }\r\n  <span>\r\n    {{ policyTranslatedText[1] }}\r\n  </span>\r\n}\r\n\r\n@if (!cookiePolicyUrl && privacyPolicyUrl) {\r\n  <span>\r\n    {{ policyTranslatedText[0] }}\r\n  </span>\r\n  @if (!isHrefPrivacy) {\r\n    <a class=\"text-white\" [routerLink]=\"[privacyPolicyUrl]\">{{ privacyPolicy }}</a>\r\n  }\r\n  @if (isHrefPrivacy) {\r\n    <a class=\"text-white\" target=\"_blank\" [href]=\"[privacyPolicyUrl]\">{{ privacyPolicy }}</a>\r\n  }\r\n  <span>\r\n    {{ policyTranslatedText[1] }}\r\n  </span>\r\n}\r\n"
    }]
  }], () => [{
    type: i1.LocalizationService
  }, {
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }], {
    link: [{
      type: Input
    }]
  });
})();
class LocalizeLinkDirective {
  constructor(vcRef) {
    this.vcRef = vcRef;
  }
  ngOnInit() {
    const createdComponent = this.vcRef.createComponent(LinkComponent);
    createdComponent.instance.link = this.link;
  }
  static {
    this.ɵfac = function LocalizeLinkDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LocalizeLinkDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: LocalizeLinkDirective,
      selectors: [["", "abpLocalizeLink", ""]],
      inputs: {
        link: "link"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalizeLinkDirective, [{
    type: Directive,
    args: [{
      selector: '[abpLocalizeLink]'
    }]
  }], () => [{
    type: i0.ViewContainerRef
  }], {
    link: [{
      type: Input
    }]
  });
})();
class GdprCookieConsentComponent {
  constructor() {
    this.document = inject(DOCUMENT);
    this.cookieConsentService = inject(CookieConsentService);
    this.componentKey = eGdprConfigComponents.CookieConsent;
    this.cookieKey = '.AspNet.Consent';
    this.showCookieConsent = true;
    this.consentOptions$ = this.cookieConsentService.cookieConsentOptions$;
  }
  ngOnInit() {
    this.checkCookieConsent();
  }
  checkCookieConsent() {
    const decodedCookies = decodeURIComponent(this.document.cookie);
    this.showCookieConsent = !decodedCookies.includes(`${this.cookieKey}=true`);
  }
  acceptCookie() {
    const {
      expireDate
    } = this.cookieConsentService.cookieConsentOptions;
    if (!expireDate || typeof expireDate !== 'object') {
      return;
    }
    this.document.cookie = this.cookieKey + '=true;expires=' + expireDate.toUTCString() + ';path=/';
    this.showCookieConsent = false;
  }
  static {
    this.ɵfac = function GdprCookieConsentComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || GdprCookieConsentComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: GdprCookieConsentComponent,
      selectors: [["abp-gdpr-cookie-consent"]],
      decls: 1,
      vars: 3,
      consts: [[4, "abpReplaceableTemplate"], ["id", "cookieConsent", "role", "alert", 1, "position-fixed", "z-9999"], [1, "row"], [1, "col-12"], [1, "d-flex", "flex-row", "justify-content-between", "align-items-center", "bg-primary", "text-white", "px-2"], [1, "m-2"], [1, "m-0"], ["abpLocalizeLink", "", 3, "link"], ["type", "button", 1, "btn", "btn-info", "btn-sm", 3, "click"]],
      template: function GdprCookieConsentComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, GdprCookieConsentComponent_ng_container_0_Template, 3, 3, "ng-container", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("abpReplaceableTemplate", i0.ɵɵpureFunction1(1, _c1, ctx.componentKey));
        }
      },
      dependencies: [i1.ReplaceableTemplateDirective, LocalizeLinkDirective, i3.AsyncPipe, i1.LocalizationPipe],
      styles: ["#cookieConsent[_ngcontent-%COMP%]{left:0;right:0;bottom:0;z-index:999999}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GdprCookieConsentComponent, [{
    type: Component,
    args: [{
      selector: 'abp-gdpr-cookie-consent',
      template: "<ng-container *abpReplaceableTemplate=\"{ componentKey }\">\r\n  @if (consentOptions$ | async; as options) {\r\n    @if (options.isEnabled && showCookieConsent) {\r\n      <div id=\"cookieConsent\" role=\"alert\" class=\"position-fixed z-9999\">\r\n        <div class=\"row\">\r\n          <div class=\"col-12\">\r\n            <div\r\n              class=\"d-flex flex-row justify-content-between align-items-center bg-primary text-white px-2\"\r\n            >\r\n              <div class=\"m-2\">\r\n                <p class=\"m-0\">\r\n                  {{ 'AbpGdpr::ThisWebsiteUsesCookie' | abpLocalization }}\r\n\r\n                  <ng-container abpLocalizeLink [link]=\"options\"></ng-container>\r\n                </p>\r\n              </div>\r\n\r\n              <button class=\"btn btn-info btn-sm\" type=\"button\" (click)=\"acceptCookie()\">\r\n                {{ 'AbpGdpr::Accept' | abpLocalization }}\r\n              </button>\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    }\r\n  }\r\n</ng-container>\r\n",
      styles: ["#cookieConsent{left:0;right:0;bottom:0;z-index:999999}\n"]
    }]
  }], null, null);
})();
const GDPR_USER_MENU_ITEM_PROVIDERS = {
  provide: APP_INITIALIZER,
  multi: true,
  deps: [UserMenuService, Router],
  useFactory: addToUserMenu
};
function addToUserMenu(userMenu, router) {
  return () => {
    userMenu.addItems([{
      id: 'Gdpr.GdprNavigation',
      order: 100,
      textTemplate: {
        text: 'AbpGdpr::Menu:PersonalData',
        icon: 'fa fa-lock'
      },
      action: () => router.navigateByUrl('/gdpr')
    }]);
  };
}
function cookieConsentOptionsProvider(options = {}) {
  return {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: () => {
      const cookieConsentService = inject(CookieConsentService);
      const defaultExpireDate = new Date(new Date().getFullYear(), new Date().getMonth() + 6, new Date().getDate());
      return () => {
        cookieConsentService.setOptions({
          isEnabled: options.isEnabled || true,
          expireDate: options.expireDate || defaultExpireDate,
          ...options
        });
      };
    }
  };
}
var GdprFeatureKind;
(function (GdprFeatureKind) {
  GdprFeatureKind[GdprFeatureKind["CookieConsentOptions"] = 0] = "CookieConsentOptions";
})(GdprFeatureKind || (GdprFeatureKind = {}));
function makeGdprFeature(kind, providers) {
  return {
    ɵkind: kind,
    ɵproviders: providers
  };
}
function withCookieConsentOptions(options) {
  return makeGdprFeature(GdprFeatureKind.CookieConsentOptions, [cookieConsentOptionsProvider(options)]);
}
function provideGdprConfig(...features) {
  const providers = [GDPR_USER_MENU_ITEM_PROVIDERS, cookieConsentOptionsProvider()];
  for (const feature of features) {
    providers.push(...feature.ɵproviders);
  }
  return makeEnvironmentProviders([providers]);
}
const declarations = [GdprCookieConsentComponent, LocalizeLinkDirective, LinkComponent];
class GdprConfigModule {
  /**
   * @deprecated forRoot method is deprecated, use `provideAbpCore` *function* for config settings.
   */
  static forRoot(options) {
    return {
      ngModule: GdprConfigModule,
      providers: [provideGdprConfig(withCookieConsentOptions(options))]
    };
  }
  static {
    this.ɵfac = function GdprConfigModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || GdprConfigModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: GdprConfigModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CoreModule, RouterModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GdprConfigModule, [{
    type: NgModule,
    args: [{
      declarations: [...declarations],
      imports: [CoreModule],
      exports: [...declarations, RouterModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CookieConsentService, GDPR_USER_MENU_ITEM_PROVIDERS, GdprConfigModule, GdprCookieConsentComponent, GdprFeatureKind, LinkComponent, LocalizeLinkDirective, addToUserMenu, cookieConsentOptionsProvider, eGdprConfigComponents, provideGdprConfig, withCookieConsentOptions };
