import { RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const PST_PROGRESS_OVERVIEW_ROUTE_PROVIDER = [
  {provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true},
];

function configureRoutes(routes: RoutesService) {
  return () => {
  };
}
