import { RoutesService } from '@abp/ng.core';
import * as i0 from '@angular/core';
import { APP_INITIALIZER, makeEnvironmentProviders, NgModule } from '@angular/core';
import { TEXT_TEMPLATE_MANAGEMENT_FEATURES_PROVIDERS } from '@volo/abp.ng.text-template-management/common';
const TEXT_TEMPLATE_MANAGEMENT_ROUTE_PROVIDERS = [{
  provide: APP_INITIALIZER,
  useFactory: configureRoutes,
  deps: [RoutesService],
  multi: true
}];
function configureRoutes(routes) {
  return () => {
    routes.add([{
      name: "TextTemplateManagement::Menu:TextTemplates" /* eTextTemplateManagementRouteNames.TextTemplates */,
      path: '/text-template-management/text-templates',
      parentName: "AbpUiNavigation::Menu:Administration" /* eThemeSharedRouteNames.Administration */,
      order: 5,
      layout: "application" /* eLayoutType.application */,
      requiredPolicy: "TextTemplateManagement.TextTemplates" /* eTextTemplateManagementPolicyNames.TextTemplates */,
      iconClass: 'fas fa-envelope-open-text'
    }]);
  };
}
function provideTextTemplateManagementConfig() {
  return makeEnvironmentProviders([TEXT_TEMPLATE_MANAGEMENT_ROUTE_PROVIDERS, TEXT_TEMPLATE_MANAGEMENT_FEATURES_PROVIDERS]);
}

/**
 * @deprecated TextTemplateManagementConfigModule is deprecated use `provideTextTemplateManagementConfig` *function* instead.
 */
class TextTemplateManagementConfigModule {
  static forRoot() {
    return {
      ngModule: TextTemplateManagementConfigModule,
      providers: [provideTextTemplateManagementConfig()]
    };
  }
  static {
    this.ɵfac = function TextTemplateManagementConfigModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TextTemplateManagementConfigModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TextTemplateManagementConfigModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TextTemplateManagementConfigModule, [{
    type: NgModule
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TEXT_TEMPLATE_MANAGEMENT_ROUTE_PROVIDERS, TextTemplateManagementConfigModule, configureRoutes, provideTextTemplateManagementConfig };
