<div (keyup.enter)="submit()">
    <h2>{{ '::AgentUpdates' | abpLocalization }}</h2>
    <hr class="my-4" />
    <form *ngIf="form" [formGroup]="form">
        <div class="mb-2 form-check">
            <input
              class="form-check-input"
              formControlName="enableAutoUpdate"
              id="enable-auto-update"
              name="enableAutoUpdate"
              type="checkbox"
            /><label class="form-check-label" for="use-ssl">{{
            '::EnableAgentAutoUpdate' | abpLocalization
            }}</label>
          </div>
          <div class="mb-3 form-group">
            <label class="form-check-label">{{ '::MaxAgents' | abpLocalization }}</label>
            <input class="form-control" formControlName="agents" type="number"/>
          </div>
          <div class="mb-3 form-group">
            <label class="form-check-label">{{ '::MinimumAgentWatchdogVersion' | abpLocalization }}</label>
            <input class="form-control" formControlName="minimumAgentWatchdogVersion" type="text"/>
          </div>
    </form>
    <hr class="my-4"/>
    <div>
        <abp-button (click)="submit()" [loading]="loading" [disabled]="form?.invalid" iconClass="fa fa-save">{{
          'AbpAccount::Save' | abpLocalization
          }}</abp-button>
      </div>
</div>