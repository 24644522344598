import { ConfirmationService } from '@abp/ng.theme.shared';
import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SettingEntryDto } from '@proxy/application-settings';
import { SettingDefinition } from '@proxy/application-settings/enums';
import { SettingsService } from '@proxy/settings/settings.service';
import { AbstractAccountSettingsComponent, AbstractAccountSettingsService, AccountCaptchaService } from '@volo/abp.ng.account/admin';

export interface AgentUpdateSettings{
  isEnabled:boolean;
  agents:number;
}

@Component({
  selector: 'abp-agent-update-settings',
  templateUrl: './agent-update-settings.component.html',
  styleUrls: ['./agent-update-settings.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: AbstractAccountSettingsService,
      useClass: AccountCaptchaService,
    },
    SettingsService
  ],
})  
export class AgentUpdateSettingsComponent
extends AbstractAccountSettingsComponent<AgentUpdateSettings> implements OnInit {
  form: UntypedFormGroup;
  enableAutoUpdate:any;
  agents:any;
  minimumAgentWatchdogVersion:any;
  settingEntryDto:SettingEntryDto[]=[];
  
  constructor(
    protected injector: Injector,
    private fb: UntypedFormBuilder,
    private settingsService: SettingsService,
    private confirmation:ConfirmationService,
  ) {super(); }

  ngOnInit(): void {
    super.ngOnInit();
    this.settingsService.getAgentUpdateSettingValues().subscribe(result=>{
      result.forEach(element => {
        switch (element.settingDefinitionId) {
          case SettingDefinition.AgentAutoUpdate:
            this.enableAutoUpdate = element;
            break;
          case SettingDefinition.MaxParallelAgentUpdates:
            this.agents = element;
            break;
          case SettingDefinition.MinimumAgentWatchdogVersion:
            this.minimumAgentWatchdogVersion = element;
            break;
        }
        this.settingEntryDto.push({
          settingDefinition: element, 
          settingEntryId: 0,
          settingDefinitionId: element.settingDefinitionId, 
          settingDefinitionDataTypeId: element.settingDefinitionDataTypeId,
        });
      });
      this.buildForm();
    },
    error => {alert("There was an error retrieving Agnet Update Settings: " + error.message)})
  }
  protected buildForm() {
    this.form = this.fb.group({
      agents: [this.agents.defaultNumeric, [Validators.required, Validators.min(this.agents.min), Validators.max(this.agents.max)]],
      minimumAgentWatchdogVersion: [this.minimumAgentWatchdogVersion.defaultText, [Validators.required, Validators.pattern('^([0-9]+\d*|0)(\.(([0-9]+\d*)|0)){0,3}$')]],
      enableAutoUpdate: [this.enableAutoUpdate.defaultNumeric, []]
    });
    this.cdr.detectChanges();
  }
  submit() {
    if (this.form.invalid) return;
    this.settingEntryDto.forEach(x => {
      switch(x.settingDefinitionId){
        case SettingDefinition.AgentAutoUpdate:
          x.valueNumeric=this.form.value.enableAutoUpdate ? 1 : 0;
          break;
        case SettingDefinition.MaxParallelAgentUpdates:
          x.valueNumeric = this.form.value.agents;
          break;
        case SettingDefinition.MinimumAgentWatchdogVersion:
          x.valueText = this.form.value.minimumAgentWatchdogVersion;
          break;
      }
    });
    this.settingsService.createOrUpdateSettingEntryByCreateSettingEntry(this.settingEntryDto).subscribe(result => {
      this.confirmation.success('::Saved', '::Success', {
        hideCancelBtn: false,
        hideYesBtn: true,
        cancelText: 'Close',
      });
    })
  }
}
