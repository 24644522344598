import * as i0 from '@angular/core';
import { APP_INITIALIZER, makeEnvironmentProviders, NgModule } from '@angular/core';
import { RoutesService } from '@abp/ng.core';
import { LANGUAGE_MANAGEMENT_FEATURES_PROVIDERS } from '@volo/abp.ng.language-management/common';
const LANGUAGE_MANAGEMENT_ROUTE_PROVIDERS = [{
  provide: APP_INITIALIZER,
  useFactory: configureRoutes,
  deps: [RoutesService],
  multi: true
}];
function configureRoutes(routes) {
  return () => {
    routes.add([{
      name: "LanguageManagement::LanguageManagement" /* eLanguageManagementRouteNames.LanguageManagement */,
      path: '/language-management',
      layout: "application" /* eLayoutType.application */,
      parentName: "AbpUiNavigation::Menu:Administration" /* eThemeSharedRouteNames.Administration */,
      iconClass: 'fa fa-globe',
      order: 4,
      requiredPolicy: "LanguageManagement.Languages || LanguageManagement.LanguageTexts" /* eLanguageManagementPolicyNames.LanguageManagement */
    }, {
      path: '/language-management/languages',
      name: "LanguageManagement::Languages" /* eLanguageManagementRouteNames.Languages */,
      parentName: "LanguageManagement::LanguageManagement" /* eLanguageManagementRouteNames.LanguageManagement */,
      order: 1,
      requiredPolicy: "LanguageManagement.Languages" /* eLanguageManagementPolicyNames.Languages */
    }, {
      path: '/language-management/texts',
      name: "LanguageManagement::LanguageTexts" /* eLanguageManagementRouteNames.LanguageTexts */,
      parentName: "LanguageManagement::LanguageManagement" /* eLanguageManagementRouteNames.LanguageManagement */,
      order: 2,
      requiredPolicy: "LanguageManagement.LanguageTexts" /* eLanguageManagementPolicyNames.LanguageTexts */
    }]);
  };
}
function provideLanguageManagementConfig() {
  return makeEnvironmentProviders([LANGUAGE_MANAGEMENT_ROUTE_PROVIDERS, LANGUAGE_MANAGEMENT_FEATURES_PROVIDERS]);
}

/**
 * @deprecated LanguageManagementConfigModule is deprecated use `provideLanguageManagementConfig` *function* instead.
 */
class LanguageManagementConfigModule {
  static forRoot() {
    return {
      ngModule: LanguageManagementConfigModule,
      providers: [provideLanguageManagementConfig()]
    };
  }
  static {
    this.ɵfac = function LanguageManagementConfigModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LanguageManagementConfigModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: LanguageManagementConfigModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LanguageManagementConfigModule, [{
    type: NgModule
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { LANGUAGE_MANAGEMENT_ROUTE_PROVIDERS, LanguageManagementConfigModule, configureRoutes, provideLanguageManagementConfig };
