import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SubscriptionService } from '@abp/ng.core';
import { SettingsService } from '@proxy/settings/settings.service';
import { SettingEntryDto } from '@proxy/application-settings';
import { ConfirmationService } from '@abp/ng.theme.shared';
import { EmailTemplatesService } from "@proxy/email/email-templates.service";
import { SendTestEmailRequest } from '@proxy/email/models';
import { AgentCredentialsMetadataDto, AgentService } from '@proxy/agent';
import { SettingDefinitionDto } from '@proxy/application-settings/models';
import { AbstractAccountSettingsComponent, AbstractAccountSettingsService, AccountCaptchaService } from '@volo/abp.ng.account/admin';

export interface SmtpServerSettings {
  host: string;
  port: number;
  userSSL: boolean;
  useAuthentication: boolean;
  credentials: string;
}

@Component({
  selector: 'abp-custom-settings',
  templateUrl: './smtp-server-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: AbstractAccountSettingsService,
      useClass: AccountCaptchaService,
    },
    SubscriptionService,
    SettingsService
  ],
})
export class SmtpServerSettingsComponent
  extends AbstractAccountSettingsComponent<SmtpServerSettings>
  implements OnInit {

  form: UntypedFormGroup;
  testForm: UntypedFormGroup;

  host: SettingDefinitionDto;
  port: SettingDefinitionDto;
  useSSL: SettingDefinitionDto;
  useAuthentication: any;
  credentials: any;
  useDefaultCredentials: any;
  settingEntryDto: SettingEntryDto[] = [];
  agentCredentialsList: AgentCredentialsMetadataDto[];

  isModalBusy = false;
  isModalOpen = false;
  testEmail?: SendTestEmailRequest;

  constructor(
    protected injector: Injector,
    private fb: UntypedFormBuilder,
    private subscription: SubscriptionService,
    private smtpServerSettings: SettingsService,
    private emailTemplatesService: EmailTemplatesService,
    private agentService: AgentService,
    private confirmation: ConfirmationService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.agentService.getAgentCredentialMetadata().subscribe(res => {
      this.agentCredentialsList = res;
    });
    this.smtpServerSettings.getSMTPSettingsDefaultValues().subscribe(result => {
      result.forEach(element => {
        switch (element.name) {
          case 'SMTPHost':
            this.host = element;
            break;
          case 'SMTPPort':
            this.port = element;
            break;
          case 'SMTPUseSSL':
            this.useSSL = element;
            break;
          case 'SMTPUseAuthentication':
            this.useAuthentication = element;
            break;
          case 'SMTPCredentialName':
            this.credentials = element;
            break;
          case 'SMTPUseDefaultCredentials':
            this.useDefaultCredentials = element;
            break;

          default:
            break;
        }
        this.settingEntryDto.push({
          settingDefinition: element,
          settingEntryId: 0,
          settingDefinitionId: element.settingDefinitionId,
          settingDefinitionDataTypeId: element.settingDefinitionDataTypeId
        });
      });
      this.buildForm();
    },
    error => {alert("There was an error retrieving SMTP Server Settings: " + error.message)});
  }

  submit() {
    if (this.form.invalid) return;
    this.settingEntryDto.forEach(x => {
      switch (x.settingDefinition.name) {
        case 'SMTPHost':
          x.valueText = this.form.value.host;
          break;
        case 'SMTPPort':
          x.valueNumeric = this.form.value.port;
          break;
        case 'SMTPUseSSL':
          x.valueNumeric = this.form.value.useSSL ? 1 : 0;
          break;
        case 'SMTPUseAuthentication':
          x.valueNumeric = this.form.value.useAuthentication ? 1 : 0;
          break;
        case 'SMTPCredentialName':
          x.valueText = this.form.value.credential;
          break;
        case 'SMTPUseDefaultCredentials':
          x.valueNumeric = this.form.value.useDefaultCredentials ? 1 : 0;
          break;
      }
    });
    this.smtpServerSettings.createOrUpdateSettingEntryByCreateSettingEntry(this.settingEntryDto).subscribe(result => {
      this.confirmation.success('::Saved', '::Success', {
        hideCancelBtn: false,
        hideYesBtn: true,
        cancelText: 'Close',
      });
    })
  }

  openTestEmailForm() {
    this.showModal();
    this.buildTestForm();
  }

  showModal() {
    this.isModalOpen = true;
  }

  buildTestForm() {
    this.testForm = this.fb.group({
      senderAddress: [null, [Validators.required]],
      recipientAddress: [null, [Validators.required]],
      emailTemplateId: [null, []]
    });
  }

  sendTestEmail() {
    if (this.testForm.invalid)
    { 
      return;
    }

    this.testEmail = this.testForm.value;
    this.emailTemplatesService.sendTestEmailByRequest(this.testEmail).subscribe(res => {
      this.isModalOpen = false;
    });
  }

  protected buildForm() {
    this.form = this.fb.group({
      host: [this.host.defaultText, [Validators.required]],
      port: [this.port.defaultNumeric, [Validators.required, Validators.min(this.port.min), Validators.max(this.port.max)]],
      useSSL: [this.useSSL.defaultNumeric, []],
      useAuthentication: [this.useAuthentication.defaultNumeric, []],
      useDefaultCredentials: [this.useDefaultCredentials.defaultNumeric, []],
      credential: [this.credentials.defaultText, []],
    });
    this.cdr.detectChanges();
  }

}
