import { NgModule } from '@angular/core';
import { CoreModule } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgbCollapseModule, NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { PageModule } from '@abp/ng.components/page';
import { ViewsModule } from 'src/app/shared/views/views.module';
import { AgentUpdateSettingsComponent } from './agent-update-settings/agent-update-settings.component';
import { ContinuousPstScanSettingsComponent } from './continuous-pst-scan-settings/continuous-pst-scan-settings.component';
import { SmtpServerSettingsComponent } from './smtp-server-settings/smtp-server-settings.component';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';

@NgModule({
  declarations: [
    AgentUpdateSettingsComponent,
    ContinuousPstScanSettingsComponent,
    SmtpServerSettingsComponent,
  ],
  imports: [
    CoreModule,
    ThemeSharedModule,
    CommercialUiModule,
    NgxValidateCoreModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    PageModule,
    ViewsModule,
    NgxBootstrapMultiselectModule
  ]
})
export class SettingsModule { }
