<div>
  <h2>{{ '::SMTP Server Settings' | abpLocalization }}</h2>
  <hr class="my-4"/>
  <form *ngIf="form" [formGroup]="form">

    <div class="mb-3 form-group">
      <label class="form-check-label">{{ '::Host' | abpLocalization }}</label>
      <input class="form-control" formControlName="host" type="text"/>
    </div>

    <div class="mb-3 form-group">
      <label class="form-check-label">{{ '::Port' | abpLocalization }}</label>
      <input class="form-control" formControlName="port" max="65535" min="1" type="number"/>
    </div>

    <div class="mb-2 form-check">
      <input autofocus
             class="form-check-input"
             formControlName="useSSL"
             id="use-ssl"
             name="useSSL"
             type="checkbox"/>
      <label class="form-check-label" for="use-ssl">{{'::UseSSL' | abpLocalization}}</label>
    </div>
    <div class="mb-2 form-check">
      <input class="form-check-input"
             formControlName="useAuthentication"
             id="use-authentication" name="useAuthentication"
             send
             type="checkbox"/>
      <label class="form-check-label" for="use-authentication">{{'::UseAuthentication' | abpLocalization}}</label>
    </div>

    <div *ngIf="form.value.useAuthentication" class="mb-2 form-check">
      <input class="form-check-input"
             formControlName="useDefaultCredentials"
             id="use-default-credentials" name="useDefaultCredentials"
             send
             type="checkbox"/>
      <label class="form-check-label" for="use-default-credentials">{{'::UseDefaultCredentials' | abpLocalization}}</label>
    </div>

    <div *ngIf="!form.value.useDefaultCredentials && form.value.useAuthentication" class="form-group">
      <label for="credential">{{ '::Credentials' | abpLocalization }}</label>
      <select class="form-control" formControlName="credential" id="credential">
        <option *ngFor="let option of agentCredentialsList" [ngValue]="option.id">
          {{option.name}}
        </option>
      </select>
    </div>
  </form>
  <hr class="my-4"/>

  <div>
    <abp-button (click)="submit()" [disabled]="form?.invalid" [loading]="loading" class="me-1"
                iconClass="fa fa-save">
      {{'AbpAccount::Save' | abpLocalization}}
    </abp-button>
    <abp-button (click)="openTestEmailForm()">
      {{ '::SendTestEMail' | abpLocalization }}
    </abp-button>
  </div>
</div>

<abp-modal [(visible)]="isModalOpen" [busy]="isModalBusy">
  <ng-template #abpHeader>
    <h3>{{ '::SendTestEMail' | abpLocalization }}</h3>
  </ng-template>

  <ng-template #abpBody>
    <form (submit)="sendTestEmail()" [formGroup]="testForm">
      <div class="form-group mb-3">
        <label class="form-label" for="senderAddress">{{ '::FromAddress' | abpLocalization }}</label>
        <input class="form-control" formControlName="senderAddress" id="senderAddress" name="senderAddress" type="email"/>
      </div>

      <div class="form-group mb-3">
        <label class="form-label" for="recipientAddress">{{ '::ToAddress' | abpLocalization }}</label>
        <input class="form-control" formControlName="recipientAddress" id="recipientAddress" name="recipientAddress" type="email"/>
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button abpClose class="btn btn-secondary" type="button">
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>
    <abp-button (click)="sendTestEmail()" [disabled]="testForm?.invalid" [loading]="loading" iconClass="fa fa-check">
      {{ '::SendTestEMail' | abpLocalization }}
    </abp-button>
  </ng-template>
</abp-modal>
