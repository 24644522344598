import { RoutesService, AuthErrorFilterService } from '@abp/ng.core';
import * as i0 from '@angular/core';
import { APP_INITIALIZER, inject, makeEnvironmentProviders, NgModule } from '@angular/core';
const SAAS_ROUTE_PROVIDERS = [{
  provide: APP_INITIALIZER,
  useFactory: configureRoutes,
  deps: [RoutesService],
  multi: true
}];
function configureRoutes(routes) {
  return () => {
    routes.add([{
      path: '/saas',
      name: "Saas::Menu:Saas" /* eSaasRouteNames.Saas */,
      parentName: "AbpUiNavigation::Menu:Administration" /* eThemeSharedRouteNames.Administration */,
      order: 2,
      layout: "application" /* eLayoutType.application */,
      iconClass: 'fa fa-users',
      requiredPolicy: "Saas.Tenants || Saas.Editions" /* eSaasPolicyNames.Saas */
    }, {
      path: '/saas/tenants',
      name: "Saas::Tenants" /* eSaasRouteNames.Tenants */,
      parentName: "Saas::Menu:Saas" /* eSaasRouteNames.Saas */,
      order: 1,
      requiredPolicy: "Saas.Tenants" /* eSaasPolicyNames.Tenants */
    }, {
      path: '/saas/editions',
      name: "Saas::Editions" /* eSaasRouteNames.Editions */,
      parentName: "Saas::Menu:Saas" /* eSaasRouteNames.Saas */,
      order: 2,
      requiredPolicy: "Saas.Editions" /* eSaasPolicyNames.Editions */
    }]);
  };
}
const SAAS_AUTH_FILTER_PROVIDER = [{
  provide: APP_INITIALIZER,
  useFactory: configureAuthFilter,
  multi: true
}];
function configureAuthFilter() {
  const errorFilterService = inject(AuthErrorFilterService);
  const filter = {
    id: "Impersonation" /* eSaasAuthFilterNames.Impersonation */,
    executable: true,
    execute: event => {
      const {
        reason
      } = event;
      const {
        error: {
          grant_type
        }
      } = reason || {};
      return !!grant_type && grant_type === "Impersonation" /* eSaasAuthFilterNames.Impersonation */;
    }
  };
  return () => errorFilterService.add(filter);
}
function provideSaasConfig() {
  return makeEnvironmentProviders([SAAS_ROUTE_PROVIDERS, SAAS_AUTH_FILTER_PROVIDER]);
}
class SaasConfigModule {
  static forRoot() {
    return {
      ngModule: SaasConfigModule,
      providers: [provideSaasConfig()]
    };
  }
  static {
    this.ɵfac = function SaasConfigModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SaasConfigModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: SaasConfigModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SaasConfigModule, [{
    type: NgModule
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { SAAS_AUTH_FILTER_PROVIDER, SAAS_ROUTE_PROVIDERS, SaasConfigModule, configureRoutes, provideSaasConfig };
