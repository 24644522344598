import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterStateSnapshot, TitleStrategy } from "@angular/router";

@Injectable({
    providedIn: 'root',
  })
  export class PSTCompleteAppTitleStrategy extends TitleStrategy {
    constructor(private readonly title: Title) {
      super();
    }
  
    override updateTitle(routerState: RouterStateSnapshot) {
      const title = this.buildTitle(routerState);
      if (title !== undefined) {
        const hostname = window.location.hostname;
        const subdomain = hostname.split('.')[0];
        this.title.setTitle(`${subdomain} - PSTComplete`);
      }
    }
  }