import * as i0 from '@angular/core';
import { inject, Injectable, Inject, signal, APP_INITIALIZER, makeEnvironmentProviders, NgModule } from '@angular/core';
import * as i2 from '@abp/ng.core';
import { NAVIGATE_TO_MANAGE_PROFILE, EnvironmentService, AuthService, CORE_OPTIONS, IS_EXTERNAL_REQUEST, TENANT_KEY, AbpLocalStorageService, ConfigStateService, HttpErrorReporterService, SessionStateService, noop, AbstractAuthErrorFilter, AuthGuard, authGuard, ApiInterceptor, PIPE_TO_LOGIN_FN_KEY, CHECK_AUTHENTICATION_STATE_FN_KEY, AuthErrorFilterService } from '@abp/ng.core';
import { HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';
import * as i1 from 'angular-oauth2-oidc';
import { OAuthService, OAuthErrorEvent, OAuthInfoEvent, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import compare from 'just-compare';
import { map, filter, finalize, switchMap, tap, take } from 'rxjs/operators';
import { pipe, of, from, lastValueFrom, EMPTY } from 'rxjs';
import { Router } from '@angular/router';
const NavigateToManageProfileProvider = {
  provide: NAVIGATE_TO_MANAGE_PROFILE,
  useFactory: () => {
    const environment = inject(EnvironmentService);
    return () => {
      const env = environment.getEnvironment();
      if (!env.oAuthConfig) {
        console.warn('The oAuthConfig env is missing on environment.ts');
        return;
      }
      const {
        issuer
      } = env.oAuthConfig;
      const path = issuer.endsWith('/') ? issuer : `${issuer}/`;
      window.open(`${path}Account/Manage?returnUrl=${window.location.href}`, '_self');
    };
  }
};

/**
 * @deprecated Use `abpOAuthGuard` *function* instead.
 */
class AbpOAuthGuard {
  constructor() {
    this.oAuthService = inject(OAuthService);
    this.authService = inject(AuthService);
  }
  canActivate(route, state) {
    const hasValidAccessToken = this.oAuthService.hasValidAccessToken();
    if (hasValidAccessToken) {
      return true;
    }
    const params = {
      returnUrl: state.url
    };
    this.authService.navigateToLogin(params);
    return false;
  }
  static {
    this.ɵfac = function AbpOAuthGuard_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AbpOAuthGuard)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AbpOAuthGuard,
      factory: AbpOAuthGuard.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbpOAuthGuard, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const abpOAuthGuard = (route, state) => {
  const oAuthService = inject(OAuthService);
  const authService = inject(AuthService);
  const hasValidAccessToken = oAuthService.hasValidAccessToken();
  if (hasValidAccessToken) {
    return true;
  }
  const params = {
    returnUrl: state.url
  };
  authService.navigateToLogin(params);
  return false;
};
class OAuthConfigurationHandler {
  constructor(oAuthService, environmentService, options) {
    this.oAuthService = oAuthService;
    this.environmentService = environmentService;
    this.options = options;
    this.listenToSetEnvironment();
  }
  listenToSetEnvironment() {
    this.environmentService.createOnUpdateStream(state => state).pipe(map(environment => environment.oAuthConfig), filter(config => !compare(config, this.options.environment.oAuthConfig))).subscribe(config => {
      this.oAuthService.configure(config);
    });
  }
  static {
    this.ɵfac = function OAuthConfigurationHandler_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || OAuthConfigurationHandler)(i0.ɵɵinject(i1.OAuthService), i0.ɵɵinject(i2.EnvironmentService), i0.ɵɵinject(CORE_OPTIONS));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: OAuthConfigurationHandler,
      factory: OAuthConfigurationHandler.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OAuthConfigurationHandler, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.OAuthService
  }, {
    type: i2.EnvironmentService
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CORE_OPTIONS]
    }]
  }], null);
})();
class OAuthApiInterceptor {
  constructor(oAuthService, sessionState, httpWaitService, tenantKey) {
    this.oAuthService = oAuthService;
    this.sessionState = sessionState;
    this.httpWaitService = httpWaitService;
    this.tenantKey = tenantKey;
  }
  intercept(request, next) {
    this.httpWaitService.addRequest(request);
    const isExternalRequest = request.context?.get(IS_EXTERNAL_REQUEST);
    const newRequest = isExternalRequest ? request : request.clone({
      setHeaders: this.getAdditionalHeaders(request.headers)
    });
    return next.handle(newRequest).pipe(finalize(() => this.httpWaitService.deleteRequest(request)));
  }
  getAdditionalHeaders(existingHeaders) {
    const headers = {};
    const token = this.oAuthService.getAccessToken();
    if (!existingHeaders?.has('Authorization') && token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const lang = this.sessionState.getLanguage();
    if (!existingHeaders?.has('Accept-Language') && lang) {
      headers['Accept-Language'] = lang;
    }
    const tenant = this.sessionState.getTenant();
    if (!existingHeaders?.has(this.tenantKey) && tenant?.id) {
      headers[this.tenantKey] = tenant.id;
    }
    headers['X-Requested-With'] = 'XMLHttpRequest';
    return headers;
  }
  static {
    this.ɵfac = function OAuthApiInterceptor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || OAuthApiInterceptor)(i0.ɵɵinject(i1.OAuthService), i0.ɵɵinject(i2.SessionStateService), i0.ɵɵinject(i2.HttpWaitService), i0.ɵɵinject(TENANT_KEY));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: OAuthApiInterceptor,
      factory: OAuthApiInterceptor.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OAuthApiInterceptor, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.OAuthService
  }, {
    type: i2.SessionStateService
  }, {
    type: i2.HttpWaitService
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [TENANT_KEY]
    }]
  }], null);
})();
const oAuthStorage = localStorage;
function clearOAuthStorage(storage = oAuthStorage) {
  const keys = ['access_token', 'id_token', 'refresh_token', 'nonce', 'PKCE_verifier', 'expires_at', 'id_token_claims_obj', 'id_token_expires_at', 'id_token_stored_at', 'access_token_stored_at', 'granted_scopes', 'session_state'];
  keys.forEach(key => storage.removeItem(key));
}
function storageFactory() {
  return oAuthStorage;
}
class RememberMeService {
  constructor() {
    this.#rememberMe = 'remember_me';
    this.localStorageService = inject(AbpLocalStorageService);
  }
  #rememberMe;
  set(remember) {
    this.localStorageService.setItem(this.#rememberMe, JSON.stringify(remember));
  }
  remove() {
    this.localStorageService.removeItem(this.#rememberMe);
  }
  get() {
    return Boolean(JSON.parse(this.localStorageService.getItem(this.#rememberMe) || 'false'));
  }
  getFromToken(accessToken) {
    const tokenBody = accessToken.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
    const parsedToken = JSON.parse(atob(tokenBody));
    return Boolean(parsedToken[this.#rememberMe]);
  }
  static {
    this.ɵfac = function RememberMeService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || RememberMeService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: RememberMeService,
      factory: RememberMeService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RememberMeService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const pipeToLogin = function (params, injector) {
  const configState = injector.get(ConfigStateService);
  const router = injector.get(Router);
  const rememberMeService = injector.get(RememberMeService);
  const authService = injector.get(AuthService);
  return pipe(switchMap(() => configState.refreshAppState()), tap(() => {
    rememberMeService.set(params.rememberMe || rememberMeService.get() || rememberMeService.getFromToken(authService.getAccessToken()));
    if (params.redirectUrl) router.navigate([params.redirectUrl]);
  }));
};
//Ref: https://github.com/manfredsteyer/angular-oauth2-oidc/issues/1214
function isTokenExpired(expireDate) {
  const currentDate = new Date().getTime();
  return expireDate < currentDate;
}
const checkAccessToken = function (injector) {
  const configState = injector.get(ConfigStateService);
  const oAuth = injector.get(OAuthService);
  if (oAuth.hasValidAccessToken() && !configState.getDeep('currentUser.id')) {
    clearOAuthStorage();
  }
};
class AuthFlowStrategy {
  constructor(injector) {
    this.injector = injector;
    this.catchError = err => {
      this.httpErrorReporter.reportError(err);
      return of(null);
    };
    this.httpErrorReporter = injector.get(HttpErrorReporterService);
    this.environment = injector.get(EnvironmentService);
    this.configState = injector.get(ConfigStateService);
    this.oAuthService = injector.get(OAuthService);
    this.sessionState = injector.get(SessionStateService);
    this.localStorageService = injector.get(AbpLocalStorageService);
    this.oAuthConfig = this.environment.getEnvironment().oAuthConfig || {};
    this.tenantKey = injector.get(TENANT_KEY);
    this.router = injector.get(Router);
    this.oAuthErrorFilterService = injector.get(OAuthErrorFilterService);
    this.rememberMeService = injector.get(RememberMeService);
    this.listenToOauthErrors();
  }
  async init() {
    if (this.oAuthConfig.clientId) {
      const shouldClear = shouldStorageClear(this.oAuthConfig.clientId, oAuthStorage);
      if (shouldClear) clearOAuthStorage(oAuthStorage);
    }
    this.oAuthService.configure(this.oAuthConfig);
    this.oAuthService.events.pipe(filter(event => event.type === 'token_refresh_error')).subscribe(() => this.navigateToLogin());
    this.navigateToPreviousUrl();
    return this.oAuthService.loadDiscoveryDocument().then(() => {
      const isTokenExpire = isTokenExpired(this.oAuthService.getAccessTokenExpiration());
      if (isTokenExpire && this.oAuthService.getRefreshToken()) {
        return this.refreshToken();
      }
      return Promise.resolve();
    }).catch(this.catchError);
  }
  navigateToPreviousUrl() {
    const {
      responseType
    } = this.oAuthConfig;
    if (responseType === 'code') {
      this.oAuthService.events.pipe(filter(event => event.type === 'token_received' && !!this.oAuthService.state), take(1), map(() => {
        const redirectUri = decodeURIComponent(this.oAuthService.state);
        if (redirectUri && redirectUri !== '/') {
          return redirectUri;
        }
        return '/';
      }), switchMap(redirectUri => this.configState.getOne$('currentUser').pipe(filter(user => !!user?.isAuthenticated), tap(() => this.router.navigateByUrl(redirectUri))))).subscribe();
    }
  }
  refreshToken() {
    return this.oAuthService.refreshToken().catch(() => clearOAuthStorage());
  }
  listenToOauthErrors() {
    this.oAuthService.events.pipe(filter(event => event instanceof OAuthErrorEvent), tap(err => {
      const shouldSkip = this.oAuthErrorFilterService.run(err);
      if (!shouldSkip) {
        clearOAuthStorage();
      }
    }), switchMap(() => this.configState.refreshAppState())).subscribe();
  }
}
function shouldStorageClear(clientId, storage) {
  const key = 'abpOAuthClientId';
  if (!storage.getItem(key)) {
    storage.setItem(key, clientId);
    return false;
  }
  const shouldClear = storage.getItem(key) !== clientId;
  if (shouldClear) storage.setItem(key, clientId);
  return shouldClear;
}
class AuthCodeFlowStrategy extends AuthFlowStrategy {
  constructor() {
    super(...arguments);
    this.isInternalAuth = false;
  }
  async init() {
    this.checkRememberMeOption();
    return super.init().then(() => this.oAuthService.tryLogin().catch(noop)).then(() => this.oAuthService.setupAutomaticSilentRefresh());
  }
  checkRememberMeOption() {
    const accessToken = this.oAuthService.getAccessToken();
    const isTokenExpire = isTokenExpired(this.oAuthService.getAccessTokenExpiration());
    let rememberMe = this.rememberMeService.get();
    if (accessToken && !rememberMe) {
      const rememberMeValue = this.rememberMeService.getFromToken(accessToken);
      this.rememberMeService.set(!!rememberMeValue);
    }
    rememberMe = this.rememberMeService.get();
    if (accessToken && isTokenExpire && !rememberMe) {
      this.rememberMeService.remove();
      this.oAuthService.logOut();
    }
  }
  navigateToLogin(queryParams) {
    let additionalState = '';
    if (queryParams?.returnUrl) {
      additionalState = queryParams.returnUrl;
    }
    const cultureParams = this.getCultureParams(queryParams);
    this.oAuthService.initCodeFlow(additionalState, cultureParams);
  }
  checkIfInternalAuth(queryParams) {
    this.oAuthService.initCodeFlow('', this.getCultureParams(queryParams));
    return false;
  }
  logout(queryParams) {
    this.rememberMeService.remove();
    if (queryParams?.noRedirectToLogoutUrl) {
      this.router.navigate(['/']);
      return from(this.oAuthService.revokeTokenAndLogout(true));
    }
    return from(this.oAuthService.revokeTokenAndLogout(this.getCultureParams(queryParams)));
  }
  login(queryParams) {
    this.oAuthService.initCodeFlow('', this.getCultureParams(queryParams));
    return of(null);
  }
  getCultureParams(queryParams) {
    const lang = this.sessionState.getLanguage();
    const culture = {
      culture: lang,
      'ui-culture': lang
    };
    return {
      ...(lang && culture),
      ...queryParams
    };
  }
}
class AuthPasswordFlowStrategy extends AuthFlowStrategy {
  constructor() {
    super(...arguments);
    this.isInternalAuth = true;
  }
  listenToTokenExpiration() {
    this.oAuthService.events.pipe(filter(event => event instanceof OAuthInfoEvent && event.type === 'token_expires' && event.info === 'access_token')).subscribe(() => {
      if (this.oAuthService.getRefreshToken()) {
        this.refreshToken();
      } else {
        this.oAuthService.logOut();
        this.rememberMeService.remove();
        this.configState.refreshAppState().subscribe();
      }
    });
  }
  async init() {
    this.checkRememberMeOption();
    return super.init().then(() => this.listenToTokenExpiration());
  }
  checkRememberMeOption() {
    const accessToken = this.oAuthService.getAccessToken();
    const isTokenExpire = isTokenExpired(this.oAuthService.getAccessTokenExpiration());
    const rememberMe = this.rememberMeService.get();
    if (accessToken && isTokenExpire && !rememberMe) {
      this.rememberMeService.remove();
      this.oAuthService.logOut();
    }
  }
  navigateToLogin(queryParams) {
    const router = this.injector.get(Router);
    return router.navigate(['/account/login'], {
      queryParams
    });
  }
  checkIfInternalAuth() {
    return true;
  }
  login(params) {
    const tenant = this.sessionState.getTenant();
    return from(this.oAuthService.fetchTokenUsingPasswordFlow(params.username, params.password, new HttpHeaders({
      ...(tenant && tenant.id && {
        [this.tenantKey]: tenant.id
      })
    }))).pipe(pipeToLogin(params, this.injector));
  }
  logout() {
    const router = this.injector.get(Router);
    const noRedirectToLogoutUrl = true;
    return from(this.oAuthService.revokeTokenAndLogout(noRedirectToLogoutUrl)).pipe(switchMap(() => this.configState.refreshAppState()), tap(() => {
      this.rememberMeService.remove();
      router.navigateByUrl('/');
    }));
  }
  refreshToken() {
    return this.oAuthService.refreshToken().catch(() => {
      clearOAuthStorage();
      this.rememberMeService.remove();
    });
  }
}
const AUTH_FLOW_STRATEGY = {
  Code(injector) {
    return new AuthCodeFlowStrategy(injector);
  },
  Password(injector) {
    return new AuthPasswordFlowStrategy(injector);
  }
};
class AbpOAuthService {
  get oidc() {
    return this.oAuthService.oidc;
  }
  set oidc(value) {
    this.oAuthService.oidc = value;
  }
  get isInternalAuth() {
    return this.strategy.isInternalAuth;
  }
  constructor(injector) {
    this.injector = injector;
    this.oAuthService = this.injector.get(OAuthService);
  }
  async init() {
    const environmentService = this.injector.get(EnvironmentService);
    const result$ = environmentService.getEnvironment$().pipe(map(env => env?.oAuthConfig), filter(Boolean), tap(oAuthConfig => {
      this.strategy = oAuthConfig.responseType === 'code' ? AUTH_FLOW_STRATEGY.Code(this.injector) : AUTH_FLOW_STRATEGY.Password(this.injector);
    }), switchMap(() => from(this.strategy.init())), take(1));
    return await lastValueFrom(result$);
  }
  logout(queryParams) {
    if (!this.strategy) {
      return EMPTY;
    }
    return this.strategy.logout(queryParams);
  }
  navigateToLogin(queryParams) {
    this.strategy.navigateToLogin(queryParams);
  }
  login(params) {
    return this.strategy.login(params);
  }
  get isAuthenticated() {
    return this.oAuthService.hasValidAccessToken();
  }
  loginUsingGrant(grantType, parameters, headers) {
    const {
      clientId: client_id,
      dummyClientSecret: client_secret
    } = this.oAuthService;
    const access_token = this.oAuthService.getAccessToken();
    const p = {
      access_token,
      grant_type: grantType,
      client_id,
      ...parameters
    };
    if (client_secret) {
      p['client_secret'] = client_secret;
    }
    return this.oAuthService.fetchTokenUsingGrant(grantType, p, headers);
  }
  getRefreshToken() {
    return this.oAuthService.getRefreshToken();
  }
  getAccessToken() {
    return this.oAuthService.getAccessToken();
  }
  refreshToken() {
    return this.oAuthService.refreshToken();
  }
  getAccessTokenExpiration() {
    return this.oAuthService.getAccessTokenExpiration();
  }
  static {
    this.ɵfac = function AbpOAuthService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AbpOAuthService)(i0.ɵɵinject(i0.Injector));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AbpOAuthService,
      factory: AbpOAuthService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbpOAuthService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i0.Injector
  }], null);
})();
class OAuthErrorFilterService extends AbstractAuthErrorFilter {
  constructor() {
    super(...arguments);
    this._filters = signal([]);
    this.filters = this._filters.asReadonly();
  }
  get(id) {
    return this._filters().find(({
      id: _id
    }) => _id === id);
  }
  add(filter) {
    this._filters.update(items => [...items, filter]);
  }
  patch(item) {
    const _item = this.filters().find(({
      id
    }) => id === item.id);
    if (!_item) {
      return;
    }
    Object.assign(_item, item);
  }
  remove(id) {
    const item = this.filters().find(({
      id: _id
    }) => _id === id);
    if (!item) {
      return;
    }
    this._filters.update(items => items.filter(({
      id: _id
    }) => _id !== id));
  }
  run(event) {
    return this.filters().filter(({
      executable
    }) => !!executable).map(({
      execute
    }) => execute(event)).some(item => item);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵOAuthErrorFilterService_BaseFactory;
      return function OAuthErrorFilterService_Factory(__ngFactoryType__) {
        return (ɵOAuthErrorFilterService_BaseFactory || (ɵOAuthErrorFilterService_BaseFactory = i0.ɵɵgetInheritedFactory(OAuthErrorFilterService)))(__ngFactoryType__ || OAuthErrorFilterService);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: OAuthErrorFilterService,
      factory: OAuthErrorFilterService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OAuthErrorFilterService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
function provideAbpOAuth() {
  const providers = [{
    provide: AuthService,
    useClass: AbpOAuthService
  }, {
    provide: AuthGuard,
    useClass: AbpOAuthGuard
  }, {
    provide: authGuard,
    useValue: abpOAuthGuard
  }, {
    provide: ApiInterceptor,
    useClass: OAuthApiInterceptor
  }, {
    provide: PIPE_TO_LOGIN_FN_KEY,
    useValue: pipeToLogin
  }, {
    provide: CHECK_AUTHENTICATION_STATE_FN_KEY,
    useValue: checkAccessToken
  }, {
    provide: HTTP_INTERCEPTORS,
    useExisting: ApiInterceptor,
    multi: true
  }, NavigateToManageProfileProvider, {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [OAuthConfigurationHandler],
    useFactory: noop
  }, OAuthModule.forRoot().providers, {
    provide: OAuthStorage,
    useClass: AbpLocalStorageService
  }, {
    provide: AuthErrorFilterService,
    useExisting: OAuthErrorFilterService
  }];
  return makeEnvironmentProviders(providers);
}

/**
 * @deprecated AbpOAuthModule is deprecated use `provideAbpOAuth` *function* instead.
 */
class AbpOAuthModule {
  static forRoot() {
    return {
      ngModule: AbpOAuthModule,
      providers: [provideAbpOAuth()]
    };
  }
  static {
    this.ɵfac = function AbpOAuthModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AbpOAuthModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AbpOAuthModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbpOAuthModule, [{
    type: NgModule
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AUTH_FLOW_STRATEGY, AbpOAuthGuard, AbpOAuthModule, AbpOAuthService, AuthCodeFlowStrategy, AuthFlowStrategy, AuthPasswordFlowStrategy, NavigateToManageProfileProvider, OAuthApiInterceptor, OAuthConfigurationHandler, OAuthErrorFilterService, RememberMeService, abpOAuthGuard, checkAccessToken, clearOAuthStorage, isTokenExpired, oAuthStorage, pipeToLogin, provideAbpOAuth, storageFactory };
