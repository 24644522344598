import * as i0 from '@angular/core';
import { APP_INITIALIZER, makeEnvironmentProviders, NgModule } from '@angular/core';
import { RoutesService } from '@abp/ng.core';
const OPENIDDICT_ROUTE_PROVIDERS = [{
  provide: APP_INITIALIZER,
  useFactory: configureRoutes,
  deps: [RoutesService],
  multi: true
}];
function configureRoutes(routes) {
  return () => {
    routes.add([{
      name: "AbpOpenIddict::Menu:OpenIddict" /* eOpenIddictProRouteNames.Default */,
      path: '/openiddict',
      parentName: "AbpUiNavigation::Menu:Administration" /* eThemeSharedRouteNames.Administration */,
      layout: "application" /* eLayoutType.application */,
      iconClass: 'fa fa-id-badge',
      order: 3,
      requiredPolicy: "OpenIddictPro.Application" /* eOpenIddictProPolicyNames.default */
    }, {
      name: "AbpOpenIddict::Applications" /* eOpenIddictProRouteNames.Applications */,
      path: '/openiddict/Applications',
      parentName: "AbpOpenIddict::Menu:OpenIddict" /* eOpenIddictProRouteNames.Default */,
      layout: "application" /* eLayoutType.application */,
      order: 1,
      requiredPolicy: "OpenIddictPro.Application" /* eOpenIddictProPolicyNames.application */
    }, {
      name: "AbpOpenIddict::Scopes" /* eOpenIddictProRouteNames.Scopes */,
      path: '/openiddict/Scopes',
      parentName: "AbpOpenIddict::Menu:OpenIddict" /* eOpenIddictProRouteNames.Default */,
      layout: "application" /* eLayoutType.application */,
      order: 2,
      requiredPolicy: "OpenIddictPro.Scope" /* eOpenIddictProPolicyNames.scope */
    }]);
  };
}
function provideOpeniddictproConfig() {
  return makeEnvironmentProviders([OPENIDDICT_ROUTE_PROVIDERS]);
}

/**
 * @deprecated OpeniddictproConfigModule is deprecated use `provideOpeniddictproConfig` *function* instead.
 */
class OpeniddictproConfigModule {
  static forRoot() {
    return {
      ngModule: OpeniddictproConfigModule,
      providers: [provideOpeniddictproConfig()]
    };
  }
  static {
    this.ɵfac = function OpeniddictproConfigModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || OpeniddictproConfigModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: OpeniddictproConfigModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OpeniddictproConfigModule, [{
    type: NgModule
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { OPENIDDICT_ROUTE_PROVIDERS, OpeniddictproConfigModule, configureRoutes, provideOpeniddictproConfig };
