import { RoutesService, eLayoutType } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const APP_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/',
        name: '::Menu:Home',
        iconClass: 'fas fa-file-alt',
        order: 1,
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.Dashboard',
      },
      {
        path: '/dashboard',
        name: '::Menu:Dashboard',
        iconClass: 'fas fa-tachometer-alt',
        order: 2,
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.Dashboard.Host  || PSTComplete.Dashboard.Tenant',
      },
      {
        path: '',
        name: '::Menu:Management',
        iconClass: 'fas fa-tools',
        order: 3,
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.DomainUsers || PSTComplete.Mappings || PSTComplete.PSTFiles || PSTComplete.PSTFileOwnerManagement || PSTComplete.Agents',
      },
      {
        path: '/domain-users',
        iconClass: 'fas fa-users',
        name: '::Menu:DomainUsers',
        parentName: '::Menu:Management',
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.DomainUsers',
      },
      {
        path: '/mappings',
        iconClass: 'fas fa-random',
        name: '::Menu:Mappings',
        parentName: '::Menu:Management',
        layout: eLayoutType.application,
        invisible: true,
        requiredPolicy: 'PSTComplete.Mappings',
      },
      {
        path: '/mapping-progress',
        iconClass: 'fas fa-chart-line',
        name: '::Menu:ManagementMappingProgress',
        parentName: '::Menu:Management',
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.MappingProgresses',
      },
      {
        path: '/pstfiles',
        iconClass: 'fas fa-paste',
        name: '::Menu:PSTFiles',
        parentName: '::Menu:Management',
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.PSTFiles',
      },
      {
        path: '/pst-file-item-errors',
        iconClass: 'fas fa-paste',
        name: '::Menu:PstFileItemErrors',
        parentName: '::Menu:Management',
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.PstFileItemErrors',
      },
      {
        path: '/pst-file-owner-management',
        iconClass: 'fas fa-users-cog',
        name: '::Menu:OwnerManagement',
        parentName: '::Menu:Management',
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.PSTFileOwnerManagement',
      },
      {
        path: '/agents',
        iconClass: 'fas fa-user-shield',
        name: '::Menu:Agents',
        parentName: '::Menu:Management',
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.Agents',
      },
      {
        path: '/modules',
        iconClass: 'fas fa-user-shield',
        name: '::Menu:Modules',
        parentName: '::Menu:Management',
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.Modules',
      },
      {
        path: '',
        name: '::Menu:Reports',
        iconClass: 'fas fa-chart-line',
        order: 4,
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.MigrationProgressOverview || PSTComplete.PSTProgressOverview || PSTComplete.MappingProgresses || PSTComplete.ActiveAgentUpdates',
      },
      {
        path: '/migration-progress-overview',
        iconClass: 'fas fa-chart-bar',
        name: '::Menu:ProcessOverview',
        parentName: '::Menu:Reports',
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.MigrationProgressOverview',
      },
      {
        path: '/pst-progress-overview',
        iconClass: 'fas fa-user-clock',
        name: '::Menu:UserPSTOverview',
        parentName: '::Menu:Reports',
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.PSTProgressOverview',
      },
      {
        path: '/mapping-progress',
        iconClass: 'fas fa-chart-line',
        name: '::Menu:MappingProgress',
        parentName: '::Menu:Reports',
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.MappingProgresses',
      },
      {
        path: '/finished-mappings',
        iconClass: 'fas fa-chart-line',
        name: '::Menu:FinishedMappings',
        parentName: '::Menu:Reports',
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.FinishedMappings',
      },
      {
        path: '/active-agent-updates',
        iconClass: 'fas fa-user-cog',
        name: '::Menu:ActiveAgentUpdates',
        parentName: '::Menu:Reports',
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.ActiveAgentUpdates',
      },
      {
        path: '',
        name: '::Menu:Setup',
        iconClass: 'fas fa-cogs',
        order: 4,
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.Domains || PSTComplete.AADTenants || PSTComplete.CentralUncShares || PSTComplete.NetworkDefinition || PSTComplete.AgentApiKeys || PSTComplete.AgentUpdateTiers || PSTComplete.RetentionPeriods || PSTComplete.RetentionPackage || PSTComplete.RetentionPolicy || PSTComplete.EmailTemplates || PSTComplete.MigrationProcesses',
      },
      {
        path: '',
        name: '::Menu:Environment',
        iconClass: 'fas fa-layer-group',
        order: 4,
        layout: eLayoutType.application,
        invisible: true,
        requiredPolicy: '',
      },
      {
        path: '/domains',
        iconClass: 'fas fa-server',
        name: '::Menu:Domains',
        parentName: '::Menu:Setup',
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.Domains',
      },
      {
        path: '/aadtenants',
        iconClass: 'fab fa-windows',
        name: '::Menu:AADTenants',
        parentName: '::Menu:Setup',
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.AADTenants',
      },
      {
        path: '/central-unc-shares',
        iconClass: 'fas fa-sitemap',
        name: '::Menu:CentralShares',
        parentName: '::Menu:Setup',
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.CentralUncShares',
      },
      {
        path: '/network-definition',
        iconClass: 'fas fa-project-diagram',
        name: '::Menu:NetworkDefinition',
        parentName: '::Menu:Setup',
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.NetworkDefinition',
      },
      {
        path: '/agent-api-keys',
        iconClass: 'fas fa-key',
        name: '::Menu:AgentApiKeys',
        parentName: '::Menu:Setup',
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.AgentApiKeys',
      },
      {
        path: '/agent-update-tier',
        iconClass: 'fas fa-boxes',
        name: '::Menu:AgentUpdateTier',
        parentName: '::Menu:Setup',
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.AgentUpdateTiers',
      },
      {
        path: '/',
        iconClass: 'fas fa-wrench',
        name: '::Menu:RetentionManagement',
        parentName: '::Menu:Setup',
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.RetentionPeriods || PSTComplete.RetentionPackage || PSTComplete.RetentionPolicy',
      },
      {
        path: '/retention-periods',
        name: '::Menu:RetentionPeriods',
        parentName: '::Menu:RetentionManagement',
        requiredPolicy: 'PSTComplete.RetentionPeriods'
      },
      {
        path: '/retention-packages',
        name: '::Menu:RetentionPackages',
        parentName: '::Menu:RetentionManagement',
        requiredPolicy: 'PSTComplete.RetentionPackage'
      },
      {
        path: '/retention-policies',
        name: '::Menu:RetentionPolicies',
        parentName: '::Menu:RetentionManagement',
        requiredPolicy: 'PSTComplete.RetentionPolicy'
      },
      {
        path: '/email-templates',
        iconClass: 'fas fa-mail-bulk',
        name: '::Menu:EmailTemplates',
        parentName: '::Menu:Setup',
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.EmailTemplates',
      },
      {
        path: '/migration-processes',
        iconClass: 'fas fa-retweet',
        name: '::Menu:MigrationProcesses',
        parentName: '::Menu:Setup',
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.MigrationProcesses',
      },
      {
        path: '/pst-file-excludes',
        iconClass: 'fas fa-retweet',
        name: '::Menu:PstFileExcludes',
        parentName: '::Menu:Setup',
        layout: eLayoutType.application,
        requiredPolicy: 'PSTComplete.PstFileDiscoveryExclude',
      },
      {
        path: '',
        name: '::Menu:ParentMappings',
        iconClass: 'fas fa-random',
        order: 4,
        layout: eLayoutType.application,
        invisible: true,
        requiredPolicy: '',
      },
      {
        path: '',
        name: '::Menu:MigrationProgress',
        iconClass: 'fas fa-chart-line',
        order: 5,
        layout: eLayoutType.application,
        invisible: true,
        requiredPolicy: '',
      },
      {
        path: '',
        iconClass: 'fas fa-paste',
        name: '::Menu:PSTManagement',
        order: 7,
        layout: eLayoutType.application,
        invisible: true,
        requiredPolicy: '',
      },
    ]);
  };
}
