import { RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const MIGRATION_PROCESSES_MIGRATION_PROCESS_ROUTE_PROVIDER = [
  {provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true},
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      // {
      //   path: '/migration-processes/view',
      //   name: 'view',
      //   order: 0,
      //   layout: eLayoutType.application,
      //   parentName: '::Menu:MigrationProcesses',
      //  // invisible:true,
      //   requiredPolicy: 'PSTComplete.MigrationProcesses',
      //    //invisible: true,
      // }
    ]);

  };

}
