import { ConfigStateService, CoreModule } from '@abp/ng.core';
import * as i0 from '@angular/core';
import { APP_INITIALIZER, inject, makeEnvironmentProviders, NgModule } from '@angular/core';
import { AccountSettingsComponent, TimeZoneSettingComponent, AccountSettingsModule } from '@volo/abp.ng.account/admin';
import { SettingTabsService } from '@abp/ng.setting-management/config';
import { firstValueFrom, filter } from 'rxjs';
const ACCOUNT_SETTING_TAB_PROVIDERS = [{
  provide: APP_INITIALIZER,
  useFactory: configureSettingTabs,
  deps: [SettingTabsService],
  multi: true
}];
function configureSettingTabs(settingtabs) {
  const configState = inject(ConfigStateService);
  const tabsArray = [{
    name: "AbpAccount::Menu:Account" /* eAccountSettingTabNames.Account */,
    order: 100,
    requiredPolicy: 'AbpAccount.SettingManagement',
    component: AccountSettingsComponent
  }];
  return async () => {
    const kind = await firstValueFrom(configState.getDeep$('clock.kind').pipe(filter(val => val)));
    if (kind === 'Utc') {
      tabsArray.push({
        name: "AbpSettingManagement::Menu:TimeZone" /* eAccountSettingTabNames.TimeZone */,
        order: 100,
        requiredPolicy: 'SettingManagement.TimeZone',
        component: TimeZoneSettingComponent
      });
    }
    settingtabs.add(tabsArray);
  };
}
function provideAccountAdminConfig() {
  return makeEnvironmentProviders([ACCOUNT_SETTING_TAB_PROVIDERS]);
}
class AccountAdminConfigModule {
  /**
   * @deprecated forRoot method is deprecated, use `provideAccountAdminConfig` *function* for config settings.
   */
  static forRoot() {
    return {
      ngModule: AccountAdminConfigModule,
      providers: [provideAccountAdminConfig()]
    };
  }
  static {
    this.ɵfac = function AccountAdminConfigModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AccountAdminConfigModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AccountAdminConfigModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CoreModule, AccountSettingsModule, AccountSettingsModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AccountAdminConfigModule, [{
    type: NgModule,
    args: [{
      imports: [CoreModule, AccountSettingsModule],
      exports: [AccountSettingsModule],
      declarations: []
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ACCOUNT_SETTING_TAB_PROVIDERS, AccountAdminConfigModule, configureSettingTabs, provideAccountAdminConfig };
