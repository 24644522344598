import { LocalizationService } from '@abp/ng.core';
import { ConfirmationService } from '@abp/ng.theme.shared';
import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SettingEntryDto } from '@proxy/application-settings';
import { SettingDefinition } from '@proxy/application-settings/enums';
import { PstScanTarget, pstScanTargetOptions } from '@shared/pst-scan-target.enum';
import { SettingsService } from '@proxy/settings/settings.service';
import {
  AbstractAccountSettingsComponent,
  AbstractAccountSettingsService,
  AccountCaptchaService
} from '@volo/abp.ng.account/admin';
import { IMultiSelectSettings } from 'ngx-bootstrap-multiselect';
import { pstScanExclusionOptions } from '@shared/pst-scan-exclusion.enum';

export interface PstScanSettings {
  isEnabled: boolean;
  pSTScanInterval: number;
}

@Component({
  selector: 'abp-continuous-pst-scan-settings',
  templateUrl: './continuous-pst-scan-settings.component.html',
  styleUrls: ['./continuous-pst-scan-settings.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: AbstractAccountSettingsService,
    useClass: AccountCaptchaService
  }, SettingsService]
})
export class ContinuousPstScanSettingsComponent extends AbstractAccountSettingsComponent<PstScanSettings> implements OnInit {

  form: UntypedFormGroup;

  enablePSTScan: any;

  autoEnableDiscoveryOnNewAgents: any;

  autoEnableDiscoveryOnNewUsers: any;

  pstScanInterval: any;

  pstScanTargetObject: any;
  pstScanTargetExclusionsObject: any;

  settingEntryDto: SettingEntryDto[] = [];

  pstScanTarget: any[] = [];
  pstScanTargetExclusions: any[] = [];

  scan = pstScanTargetOptions;
  scanExclusions = pstScanExclusionOptions;

  selectionDropDown = [];

  selectedStep = [];

  settings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 3,
    displayAllSelectedText: true
  };

  constructor(
    protected injector: Injector,
    private fb: UntypedFormBuilder,
    private settingService: SettingsService,
    private confirmation:ConfirmationService,
    private localizationService: LocalizationService
  ) { super();}

  ngOnInit(): void {
    super.ngOnInit();
    this.settingService.getPSTScanSettings()
      .subscribe(result => {
        result.forEach(element => {
          switch (element.settingDefinitionId) {
            case SettingDefinition.AutoEnableDiscoveryOnNewAgents:
              this.autoEnableDiscoveryOnNewAgents = element;
              break;
            case SettingDefinition.AutoEnableDiscoveryOnNewUsers:
              this.autoEnableDiscoveryOnNewUsers = element;
              break;
            case SettingDefinition.ContinuousPSTScanEnabled:
              this.enablePSTScan = element;
              break;
            case SettingDefinition.PSTScanInterval:
              this.pstScanInterval = element;
              break;
            case SettingDefinition.DiscoverPSTTargets:
              this.pstScanTargetObject = element;
              this.pstScanTarget = [];
              this.scan.forEach(x => {
                this.pstScanTarget.push({ 'id': x.value, 'name': this.localizationService.instant(`::Enum:${x.key}`) });
              });
              this.selectionDropDown = this.pstScanTarget;
              let val = this.pstScanTargetObject.defaultNumeric;
              if (val != null) {
                this.pstScanTargetObject.scanTarget = Object.values(PstScanTarget)
                  .filter(v => typeof v === 'number' && (val & v) !== 0)
                  .map(v => v as number);
              }
              break;
            case SettingDefinition.DiscoverPSTTargetExclusions:
              this.pstScanTargetExclusionsObject = element;
              this.pstScanTargetExclusions = [];
              this.scanExclusions.forEach(x => {
                this.pstScanTargetExclusions.push({ 'id': x.value, 'name': this.localizationService.instant(`::Enum:${x.key}`) });
              });
              this.selectionDropDown = this.pstScanTarget;
              let item = this.pstScanTargetExclusionsObject.defaultNumeric;
              if (item != null) {
                this.pstScanTargetExclusionsObject.scanTarget = Object.values(PstScanTarget)
                  .filter(v => typeof v === 'number' && (item & v) !== 0)
                  .map(v => v as number);
              }
              break;
          }
          this.settingEntryDto.push({
            settingDefinition: element,
            settingEntryId: 0,
            settingDefinitionId: element.settingDefinitionId,
            settingDefinitionDataTypeId: element.settingDefinitionDataTypeId
          });
        });
        this.buildForm();
      }, error => {
        alert('There was an error retrieving PST Scan Settings: ' + error.message);
      });
  }

  onScanChange(event) {
    this.pstScanTargetObject.scanTarget = event;
    this.pstScanTargetObject.defaultNumeric = null;
    event.forEach(element => {
      this.pstScanTargetObject.defaultNumeric |= element;
    });
  }

  onExclusionsScanChange(event) {
    this.pstScanTargetExclusionsObject.scanTarget = event;
    this.pstScanTargetExclusionsObject.defaultNumeric = null;
    event.forEach(element => {
      this.pstScanTargetExclusionsObject.defaultNumeric |= element;
    });
  }

  submit() {
    if (this.form.invalid) return;
    this.settingEntryDto.forEach(x => {
      switch (x.settingDefinitionId) {
        case SettingDefinition.AutoEnableDiscoveryOnNewAgents:
          x.valueNumeric = this.form.value.autoEnableDiscoveryOnNewAgents === true || this.form.value.autoEnableDiscoveryOnNewAgents === 1 ? 1 : 0;
          break;
        case SettingDefinition.AutoEnableDiscoveryOnNewUsers:
          x.valueNumeric = this.form.value.autoEnableDiscoveryOnNewUsers === true || this.form.value.autoEnableDiscoveryOnNewUsers === 1 ? 1 : 0;
          break;
        case SettingDefinition.ContinuousPSTScanEnabled:
          x.valueNumeric = this.form.value.enablePstScan === true || this.form.value.enablePstScan === 1 ? 1 : 0;
          break;
        case SettingDefinition.PSTScanInterval:
          x.valueNumeric = this.form.value.interval;
          break;
        case SettingDefinition.DiscoverPSTTargets:
          x.valueNumeric = this.form.get('pstTarget').value?.reduce((a, b) => a + b, 0);
          break;
        case SettingDefinition.DiscoverPSTTargetExclusions:
          x.valueNumeric = this.form.get('pstTargetExclusions').value?.reduce((a, b) => a + b, 0);
          break;

      }
    });
    this.settingService.createOrUpdateSettingEntryByCreateSettingEntry(this.settingEntryDto).subscribe(result => {
      this.confirmation.success('::Saved', '::Success', {
        hideCancelBtn: false, hideYesBtn: true, cancelText: 'Close'
      });
    });
  }

  protected buildForm() {
    this.form = this.fb.group({
      interval: [this.pstScanInterval.defaultNumeric, [Validators.required, Validators.min(this.pstScanInterval.min), Validators.max(this.pstScanInterval.max)]],
      enablePstScan: [this.enablePSTScan.defaultNumeric == null ? false : this.enablePSTScan.defaultNumeric, []],
      autoEnableDiscoveryOnNewAgents: [this.autoEnableDiscoveryOnNewAgents.defaultNumeric == null ? false : this.autoEnableDiscoveryOnNewAgents.defaultNumeric, []],
      autoEnableDiscoveryOnNewUsers: [this.autoEnableDiscoveryOnNewUsers.defaultNumeric == null ? false : this.autoEnableDiscoveryOnNewUsers.defaultNumeric, []],
      pstTarget: [this.pstScanTargetObject.scanTarget, [Validators.required]],
      pstTargetExclusions: [this.pstScanTargetExclusionsObject.scanTarget]
    });
    this.cdr.detectChanges();
  }
}
